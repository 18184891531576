import Experince from "../components/Experience";
import Education from "../components/Education";
import Skills from "../components/Skills";
import "./Home.css";
import Strings from "../strings.json"

const Home = () => {
    return (
        <div className="home-container">
            <div className="home">
                <div className="header">
                    <div className="name-container">
                        <h1 className="firstLastName">
                            <p className="fristName">{Strings.firstName}</p>
                            <p className="lastName">{Strings.lastName}</p>
                        </h1>
                        <div className="title-container">
                            <h2 className="titles">
                                <p className="title">{Strings.titleOne}</p>
                                <p className="title">{Strings.titleTwo}</p>
                                <p className="title">{Strings.titleThree}</p>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="profile-image">
                    <img
                        className="headshot-icon"
                        loading="lazy"
                        alt=""
                        src="/headshot@2x.png"
                    />
                </div>
            </div>
            <Experince />
            <Education />
            <Skills />
        </div>
    );
};

export default Home;
